import React, { useState, useRef, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

// Components
import Pricing from "../components/subscriptions/pricing/pricing"

// Container style to align cards on page
import "../scss/pages/home.scss"

export default function Home() {
  const rootNode = useRef(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var docRef = firebase.firestore().collection("users").doc(user.uid)

        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("doc", doc.data())
              navigate("/dashboard")
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!")
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error)
          })
      }
    })
    setLoading(false)
  })

  return (
    <div className="theme--default" ref={rootNode}>
      <Layout>
        <SEO title="Subscriptions" />
        <Navigation />
        <div className="container">
          <div>
            <Pricing />
          </div>
        </div>
        <Footer />
      </Layout>
    </div>
  )
}
