export default {
	basic: {
		title: "Small",
		price: 4.99,
		benefits: ["Support the Day community for $4.99 per month."],
		stripePrice: "price_1IZbjsDWfkQNv4XDJfkdEpkT"
	},
	professional: {
		title: "Medium",
		price: 14.99,
		benefits: ["Support the Day community for $14.99 per month."],
		stripePrice: "price_1IrmpHDWfkQNv4XDLYpKOd35"
	},
	master: {
		title: "Grand",
		price: 49.99,
		benefits: ["Support the Day community for $49.99 per month."],
		stripePrice: "price_1IsXUyDWfkQNv4XD12bbge9C"
	},
};
