import React, { useState, useEffect } from "react"
import SubscriptionsOverview from "../../../components/sections/subscriptions-overview"
import NoFees from "../../../components/sections/no-fees"

// Components
import Card from "../card/card"

// Plan price data
import plans from "./plans"

// Style
import "./styles.scss"

export default function Pricing() {
  const [planPeriod, setPlanPeriod] = useState("monthly")

  function togglePeriod(sliderValue) {
    sliderValue ? setPlanPeriod("monthly") : setPlanPeriod("annually")
  }

  return (
    <div className="pricing">
      <SubscriptionsOverview />
      <div className="card-container">
        <Card
          position="left"
          title={plans.basic.title}
          stripePrice={plans.basic.stripePrice}
          price={plans.basic.price}
          items={plans.basic.benefits}
        />
        <Card
          primary
          title={plans.professional.title}
          stripePrice={plans.professional.stripePrice}
          price={plans.professional.price}
          items={plans.professional.benefits}
        />
        <Card
          position="right"
          title={plans.master.title}
          stripePrice={plans.master.stripePrice}
          price={plans.master.price}
          items={plans.master.benefits}
        />
      </div>
      <NoFees />
    </div>
  )
}
