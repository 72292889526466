import React, { useState, useContext, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../../../context/auth"
import logo from "../../../images/day-small.png"
import styled from "styled-components"
import { navigate } from "gatsby"

import "./styles.scss"

export default function Card({
  position,
  primary,
  title,
  price,
  items,
  stripePrice,
}) {
  const [loading, setLoading] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState(false)
  const [currentUserUid, setCurrentUserUid] = useState("")

  let stripePromise

  const checkOut = async (stripePrice) => {
    if (stripePrice === "price_1IZbjsDWfkQNv4XDJfkdEpkT") {
      navigate(`https://buy.stripe.com/bIY4jMaNqcai8ne289`)
    } else if (stripePrice === "price_1IrmpHDWfkQNv4XDLYpKOd35") {
      navigate(`https://buy.stripe.com/fZedUmaNq3DMavm288`)
    } else if (stripePrice === "price_1IsXUyDWfkQNv4XD12bbge9C") {
      navigate(`https://buy.stripe.com/8wM3fI6xa1vE6f6cMO`)
    }
  }


  /*
		position -> Removes border styling on right/left side if provided - str - left | right  
    primary -> flag that changes coloring - bool
    title -> plan title - str
    price -> plan price - str
    items -> plan features - array
  */

  // Determine what classes to add to the card based on props
  let cardClass = "card "

  if (primary) {
    cardClass += "primary"
  } else {
    cardClass += "secondary"
  }

  switch (position) {
    case "left":
      cardClass += " left"
      break
    case "right":
      cardClass += " right"
      break
    default:
      break
  }

  if (loading) {
    return (
      <HeaderWrapper id="top">
        <div>
          <LoadingWrapper>
            <img src={logo} />
          </LoadingWrapper>
        </div>
      </HeaderWrapper>
    )
  } else {
    return (
      <div className={cardClass}>
        <h1>{title}</h1>

        <h2>
          <span>$</span>
          {price}
        </h2>

        <ul>
          {items.map((item, idx) => {
            return <li key={idx}>{item}</li>
          })}
        </ul>

        <h4>
          <button
            onClick={() => {
              checkOut(stripePrice)
            }}
            style={{ color: "black", background: "none" }}
          >
            Subscribe
          </button>
        </h4>
      </div>
    )
  }
}

const LoadingWrapper = styled.div`
  opacity: 0.3;
`
const HeaderWrapper = styled.header`
  background-color: #ffffff;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
