import React from "react"
import styled from "styled-components"
import { Section, Container } from "../global"

const SubscriptionsOverview = () => (
  <StyledContainer>
      <Subtitle>Show Your Support</Subtitle>
      <SectionTitle>Support Our Dailyness & Community</SectionTitle>
      <FeatureText>The Day Community puts in the work, from meditation, to taking care of our families. Support us as we continue to make the world a better place for all!</FeatureText>
    </StyledContainer>
)

export default SubscriptionsOverview

const StyledContainer = styled(Container)`
  margin-top:30px;
  background-color:white;
`

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 4px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
  padding-left:15px;
  padding-right:15px;
`
