import React from "react"
import styled from "styled-components"
import noFees from "../../images/no-fees.jpg"

import { Section, Container } from "../global"

const NoFees = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Dailyness & Bitcoin</Subtitle>
      <SectionTitle>Always Free</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>
            <img
              style={{
                maxHeight: "30px",
                width: "auto",
                position: "relative",
                top: "5px",
                marginRight: "3px",
              }}
              alt="Feature"
              src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fintention.png?alt=media&token=6446f53a-ffe0-4e4b-a83d-1f59c185ce5e"
            />
            No Fees
          </FeatureTitle>
          <FeatureText>
            We are here to support our community, not to take fees. Practice
            Daily. Earn Bitcoin. Always Free.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <img
            style={{
              maxHeight: "140px",
              width: "auto",
            }}
            alt="Feature"
            src={noFees}
          />
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default NoFees

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 4px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
